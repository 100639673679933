import {Link, useLocation} from "react-router-dom"
import classnames from "classnames"
import React from "react"

import style from "./AppBar.module.scss"
import logo from "../../assets/logo.svg"
import {Image} from "react-bootstrap"

const SideBar = () => {

    const location = useLocation()
    const {pathname} = location

    return (
        <>
            <div className={`d-flex flex-column h-100 me-5 mt-5 ${style.Menu}`}>
                <div className={`mb-4`}><Image style={{height: "24px"}} src={logo} /></div>
                <div><Link className={classnames({[`${style.On}`]: pathname === "/about"})} to={"/about"}>About</Link></div>
                <div><Link className={classnames({[`${style.On}`]: pathname.startsWith("/facilities")})} to={"/facilities"}>Facilities</Link></div>
                {/*<div><Link className={classnames({[`${style.On}`]: pathname === "/projects"})} to={"/projects"}>Projects</Link></div>*/}
                <div><Link className={classnames({[`${style.On}`]: pathname === "/contacts"})} to={"/contacts"}>Contact</Link></div>
            </div>
        </>
    )
}

export default SideBar
