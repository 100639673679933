import React from "react"
import "./styles/App.scss"
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom"
import NotFound from "./view/NotFound"
import Main from "./view/Main"
import FixedContainer from "./view/layout/FixedContainer"
import Project from "./view/Project"
import FacilityView from "./view/FacilityView"
import FacilityFetch from "./data/FacilityFetch"
import ContactView from "./view/ContactView"
import About from "./view/About"
import FacilityWrapperView from "./view/FacilityWrapperView"

const {getFacility} = FacilityFetch()

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path={"*"} element={<NotFound />} />

            <Route index element={<Main />} />

            <Route path={"/about"} element={<FixedContainer />}>
                <Route index element={<About />} />
            </Route>

            <Route path={"/projects"} element={<FixedContainer />}>
                <Route index element={<Project />} />
            </Route>
            <Route path={"/contacts"} element={<FixedContainer />}>
                <Route index element={<ContactView />} />
            </Route>

            <Route path={"/facilities"} element={<FixedContainer />}>
                <Route index element={<FacilityWrapperView />} />
                <Route path={"/facilities/:id"} loader={({params}) => {
                    return getFacility(params.id as string)
                }} element={<FacilityView />} />
            </Route>
        </>,
    ),
)

const App = () => {
    return (
        <>
            <RouterProvider router={router} />
        </>
    )
}

export default App
