import React from "react"
import {Link, Outlet} from "react-router-dom"
import SideBar from "./SideBar"
import AppBar from "./AppBar"
import {Container} from "react-bootstrap"
import Footer from "./Footer"
import globalStyle from "../../Global.module.scss"

const FixedContainer = () => {
    return (
        <>
            <Container className={`h-100 d-flex flex-column`}>
                <Container className={`h-100 d-flex flex-row`}>
                    <SideBar />
                    <div className={`h-100 d-flex flex-column flex-grow-1`}>
                        {<Outlet />}
                    </div>
                </Container>
                {/*<footer className="d-flex flex-wrap justify-content-center align-items-center py-2 my-4">
                    <a href="/" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"></a>
                    <span className={`mb-3 mb-md-0 text-body-secondary ${globalStyle.ciFont}`}>Copyright © 2024 Artista Inc.</span>
                </footer>*/}
            </Container>
        </>
    )
}

export default FixedContainer
