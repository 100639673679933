import {Link} from "react-router-dom"
import React from "react"
import style from "../../SectionHeader.module.scss"

const SectionHeader = (data: {className?: string, title: string, moreLink?: string}) => {


    const {title, moreLink, className} = data

    return (
        <>
            <div className={`d-flex justify-content-between ${className}`}>
                <h5 className={style.header}>{title}</h5>
                {moreLink &&
                    <div>
                        <Link to={moreLink}>more</Link>
                    </div>
                }
            </div>
        </>
    )
}

export default SectionHeader
