import {Col, Row} from "react-bootstrap"
import React, {useRef, useState} from "react"
import video from "../assets/video-origin.mp4"
import ReactPlayer from "react-player"

import thumbnail from "../assets/birdview.png"
import playIcon from "../assets/play.svg"
import {animated, config, useSpring} from "@react-spring/web"

import styles from "./Facility.module.scss"
import {useNavigate} from "react-router-dom"
import SectionHeader from "./component/text/SectionHeader"

const FacilityWrapperView = () => {

    const navigate = useNavigate()

    const ref = useRef<ReactPlayer>(null)
    const [playing, setPlaying] = useState<boolean>(false)

    const [props, api] = useSpring(
        () => ({
            from: {opacity: 0},
            to: {opacity: 1},
        }),
        [],
    )

    const {value, ...rest} = useSpring(({
            from: {value: 0.3},
            to: {value: 1},
            config: config.stiff,
            reset: true,
        }),
    )

    return (
        <>
            <Row className={`mt-6`}>
                <Col>
                    <h1 className={`align-middle`}>시설 소개</h1>
                </Col>
            </Row>
            <Row className={`mt-4`}>
                <Col>
                    {playing && <ReactPlayer ref={ref} url={video} playing={playing} width={"100%"} height={"100%"} muted={true} controls={true} onEnded={() => {
                        if (ref.current) {
                            setPlaying(false)
                        }
                    }} />}
                    {!playing && <animated.div className={`${styles.facility} position-relative`} style={props}>
                        <img alt={``} className={`img-fluid`} src={thumbnail} />
                        <animated.a onClick={() => {
                            // navigate("/facilities/stage-A")
                        }} style={{bottom: "12%"}} className={`${styles.chip} start-50`}>stage A</animated.a>
                        <animated.a onClick={() => {
                            // navigate("/facilities/stage-B-1")
                        }} style={{top: "12%", left: "22%"}} className={`${styles.chip} `}>stage B-1</animated.a>
                        <animated.a onClick={() => {
                            // navigate("/facilities/stage-B-2")
                        }} style={{top: "28%", left: "26%"}} className={`${styles.chip} `}>stage B-2</animated.a>
                        <animated.a onClick={() => {
                            // navigate("/facilities/stage-C")
                        }} style={{top: "30%", right: "20%"}} className={`${styles.chip}`}>stage C</animated.a>
                        <animated.a onClick={() => {
                            // navigate("/facilities/stage-D")
                        }} style={{top: "6%", left: "42%"}} className={`${styles.chip} `}>stage D</animated.a>
                        <animated.a className={`position-absolute bottom-50 start-50 text-black px-3 py-2`} style={{width: "70px", height: "70px", marginLeft: "-35px", marginTop: "-35px"}} onClick={() => {
                            setPlaying(true)
                            ref.current?.seekTo(0, "seconds")
                        }}><div className={`${styles.play} w-100 h-100`} style={{backgroundColor: "white", WebkitMask: `url(${playIcon}) no-repeat ceneter`, mask: `url(${playIcon}) no-repeat center`}}></div>
                        </animated.a>
                    </animated.div>}
                </Col>
            </Row>
            <Row className={`mt-5`}>
                <SectionHeader title={"편의 & 부속 시설"} moreLink={"/facilities/Convenients"}/>
            </Row>
        </>
    )
}

export default FacilityWrapperView
