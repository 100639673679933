import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"

import style from "./Main.module.scss"

import {Container, Image} from "react-bootstrap"

import logo from "../assets/logo.svg"
import {animated, useTransition} from "@react-spring/web"

const Main = () => {

    const ref = useRef<ReturnType<typeof setTimeout>[]>([])
    const [items, set] = useState<string[]>([])

    const navigate = useNavigate();

    const transitions = useTransition(items,
        {
            from: {
                opacity: 0.1,
                scale: 0.1
            },
            enter: {
                opacity: 1,
                scale: 1
            },
            leave: {
                height:0,
                opacity: 0
            },
            config: {duration: 300},
        }
    )

    const click = () => {
        ref.current.forEach(clearTimeout)
        ref.current = []
        set([])
        setTimeout(() => {
            navigate("/about")
        }, 400)
    }

    useEffect(() => {
        ref.current.forEach(clearTimeout)
        ref.current = []
        set([])
        ref.current.push(setTimeout(() => set(["A"]), 500))
    }, [])

    return (
        <>
            <Container className={`h-100`}>
                <div className={"d-flex mx-auto flex-column h-100"}>
                    <div className={`main my-auto text-center`} onClick={() => click()}>
                        {transitions(({opacity, scale, ...rest}, item) => (
                            <animated.div style={{
                                opacity: opacity,
                                scale: scale,
                            }}>
                                <Image style={{width: "240px"}} src={logo} />
                                <h1 className={`mb-4 ${style.Big}`}>Paju Wollong</h1>
                            </animated.div>
                        ))}
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Main
