import {Col, Row} from "react-bootstrap"
import style from "./Contact.module.scss"
import React from "react"
import MarkerIcon from "../assets/map-marker.svg"
import logo from "../assets/logo.svg"
import contact from "../assets/contact.png"
import {CustomOverlayMap, Map, useKakaoLoader, ZoomControl} from "react-kakao-maps-sdk"

import envelopIcon from "../assets/envelop.svg"
import locationIcon from "../assets/location.svg"
import telephoneIcon from "../assets/telephone.svg"

import SectionHeader from "./component/text/SectionHeader"

const ContactView = () => {

    const [loading, error] = useKakaoLoader({
        appkey: "c0402955f4b056393051749d2bd64d7c", // 발급 받은 APPKEY
        // ...options // 추가 옵션
    })

    return (
        <>
            <Row className={"mt-5"}>
                <Col>
                    <SectionHeader title={"월롱스테이지온"} />
                    {/*<h2 className={style.pageTitle}>경기 파주시 월롱면 엘지로 438-130</h2>*/}
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col className={"pt-4"}>
                    <img className={`me-4`} style={{width: "20px"}} src={envelopIcon} alt={""} /> <a href={"mailto:yunyoung21@hanmail.net"}>yunyoung21@hanmail.net</a>
                    <div className={`mt-3 border-bottom`}></div>
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col className={"pt-2"}>
                    <img className={`me-4`} style={{width: "20px"}} src={telephoneIcon} alt={""} /> <a href={"tel:+82 (0)2-2088-5311"}>+82 (0)2-2088-5311</a>
                    <div className={`mt-3 border-bottom`}></div>
                </Col>
            </Row>
            <Row className={"mt-2 mb-4"}>
                <Col className={"pt-2"}>
                    <img className={`me-4`} style={{width: "20px"}} src={locationIcon} alt={""} />경기도 파주시 월롱면 덕은리 엘지로 438-130
                    <div className={`mt-3 border-bottom`}></div>
                </Col>
            </Row>
            <Row className={"mt-2"}>
                <Col md={5}>
                    <img className={`img-fluid`} style={{transform: `rotate(90deg)`}} src={contact}/>
                </Col>
                <Col md={7}>
                    <Map center={{lat: 37.670765991144584, lng: 126.95901327209111}} style={{height: "100%"}} level={10} onCenterChanged={(target: kakao.maps.Map) => {
                    }}>
                        <CustomOverlayMap position={{lat: 37.8249313, lng: 126.7625315}}>
                            <div className={style.Pin}>
                                <div className={style.MapMaker}>
                                    <img height={14} src={logo}/>
                                </div>
                                <img src={MarkerIcon} alt={""} />
                            </div>
                        </CustomOverlayMap>
                        <ZoomControl position={"BOTTOMRIGHT"}/>
                    </Map>
                </Col>
            </Row>
        </>
    )
}

export default ContactView
