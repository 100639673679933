import React from "react"
import {Carousel, Col, Image, Row, Table} from "react-bootstrap"

import styles from "./About.module.scss"
import about02 from "../assets/about-02.png"
import image_01 from "../assets/about-01.jpg"
import image_02 from "../assets/sightview.png"
import image_03 from "../assets/birdview-2-light.png"
import image_04 from "../assets/birdview.png"
import {Link} from "react-router-dom"

const About = () => {

    const images = [
        image_02,
        image_03,
        image_04
    ]

    return (
        <>
            <Row className={`mt-6`}>
                <Col>
                    <h1 className={`align-middle`}>Studio</h1>
                </Col>
            </Row>
            <div className={`mt-2`}>
                <Image className={`w-100 d-block`} fluid={true} src={about02} />
            </div>
            <Row className={`mt-6 ${styles.points}`}>
                <Col md={4} className={`d-flex align-items-center flex-column`}>
                    <div className={`${styles.pointCircle}`} style={{width: "200px", height: "200px"}}>
                        <span><strong className={`${styles.pointText}`}>BEST<br/>LOCATION</strong></span>
                    </div>
                    <h5 className={`fw-normal my-3 text-center`}>상암 DMC, 여의도에서<br />교통 혼잡 시간 관계 없이 상시 20분대 접근</h5>
                </Col>
                <Col md={4} className={`d-flex align-items-center flex-column`}>
                    <div className={`${styles.pointCircle}`} style={{width: "200px", height: "200px"}}>
                        <span><strong className={`${styles.pointText}`}>BEST STUDIO</strong></span>
                    </div>
                    <h5 className={`fw-normal my-3 text-center`}>700 ~ 1100평대의 <br /> 5개의 독립된 촬영장 사용 가능</h5>
                    <p>
                        <Link className={`btn btn-secondary`} to={"/facilities"}>자세히 보기 »</Link>
                    </p>
                </Col>
                <Col md={4} className={`d-flex align-items-center flex-column`}>
                    <div className={`${styles.pointCircle}`} style={{width: "200px", height: "200px"}}>
                        <span><strong className={`${styles.pointText}`}>BEST STUDIO<br/>SUPPORT</strong></span>
                    </div>
                    <h5 className={`fw-normal my-3 text-center`}>세계 최고 수준의 작품을<br/>최고 수준의 스튜디오에서!</h5>
                </Col>
            </Row>
            <div className={`mt-6`}>
                <Carousel /*interval={1500}*/ /*pause={"hover"}*/ interval={null}>
                    {images.map((image, idx) => {
                        return (
                            <Carousel.Item key={`c-item-${idx + 1}`} className={"w-100"}>
                                <div className={"d-flex justify-content-center align-items-center w-100 overflow-hidden"} style={{maxHeight: "600px"}}>
                                    <Image className={`w-100 h-100 object-fit-fill`} src={image} />
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                {/*<Image className={`w-100 d-block`} fluid={true} src={about01_0} />*/}
            </div>
            <Row className={`mt-4`}>
                <Col>
                    <h4>세부 정보</h4>
                </Col>
            </Row>
            <Row className={`mt-2`}>
                <Col>
                    <h5>시설 개요</h5>
                    <Table>
                        <thead>
                        <tr>
                            <th>구 분</th>
                            <th>내 용</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>위 치</td>
                            <td>경기도 파주시 월롱면 덕은리 엘지로 438-130</td>
                        </tr>
                        <tr>
                            <td>대지면적/연면적</td>
                            <td>29,402㎡ (8,894.10평) / 18,356.11㎡ (5,552.72평)</td>
                        </tr>
                        <tr>
                            <td>건축규모</td>
                            <td>지하 2층, 지상3층 (4개 동, 5개 촬영소)
                            </td>
                        </tr>
                        <tr>
                            <td>용도</td>
                            <td>방송통신시설(촬영소)</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <h4>부속 시설 및 주차</h4>
                    <Table>
                        <thead>
                        <tr>
                            <th>구 분</th>
                            <th>내 용</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>촬양장 시설</td>
                            <td>냉/난방기, 방음/흡음시설, 지붕인조잔디설치, 고정식바턴</td>
                        </tr>
                        <tr>
                            <td>촬영 부속 시설</td>
                            <td>각 스튜디오별 배우대기실, 분장실, 회의실, 다목적실 등</td>
                        </tr>
                        <tr>
                            <td>기타 시설</td>
                            <td>식당, 편의점, 사무실, 기계/전기실 등</td>
                        </tr>
                        <tr>
                            <td>주차장</td>
                            <td>총 140대</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default About
