import {Col, Row, Table} from "react-bootstrap"
import {Link, useLoaderData} from "react-router-dom"
import {Facility} from "../data/FacilityFetch"

import style from "./Facility.module.scss"
import ImageGrid from "./component/image/ImageGrid"
import React from "react"

import blueprint from "../assets/mock-blueprint.png"

const FacilityView = () => {

    const data = useLoaderData() as Facility

    return (
        <>
            <Row className={"mt-6"}>
                <Col>
                    <div className={`d-flex`}>
                        <div>
                            <h1 className={`${style.pageTitle}`}>{data.title}</h1>
                        </div>
                        <div className={`align-content-end ms-auto`}>
                            <Link to={"/facilities"}>돌아가기</Link>
                        </div>
                    </div>
                </Col>
            </Row>
            {data.blocks &&
                data.blocks.map((block, index) => {
                    return (
                        <Row className={"mt-4"} key={`data-block-${index + 1}`}>
                            <Col>
                                {(typeof block.content !== "string") && <ImageGrid images={block.content ?? []} popupCarousel={true} />}
                            </Col>
                        </Row>
                    )
                })
            }
        </>
    )
}

export default FacilityView
