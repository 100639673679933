import React, {ReactElement} from "react"
import {Gallery, Item} from "react-photoswipe-gallery"

import style from "./ImageGrid.module.scss"
import "photoswipe/dist/photoswipe.css"
import {ImageItem} from "./ImageItem"

const ImageGrid = (props: {images: ImageItem[], popupCarousel?: boolean, minColumn?: number, maxColumn?: number}) => {

    const defaultProps = {
        minColumn: 2,
        maxColumn: 6,
        popupCarousel: false,
    }

    const {images, popupCarousel, minColumn, maxColumn} = {...defaultProps, ...props}

    const customContent = (image: string, caption?: string): ReactElement => {
        return (
            <>
                <div className={`${style.popupCarousel}`}>
                    <img className={`${style.popupCarouselContent}`} src={image} alt={""} />
                    <div className={`${style.popupCarouselText}`}>{caption && caption}</div>
                </div>
            </>
        )
    }

    type Props = {children?: string | React.JSX.Element | React.JSX.Element[]}

    const Container = (props: Props): React.JSX.Element => {
        const {children} = props
        if (popupCarousel) {
            return (<Gallery options={{
                showHideAnimationType: "fade",
                showAnimationDuration: 250,
                hideAnimationDuration: 150,
                initialZoomLevel: "fit",
                secondaryZoomLevel: "fit",
                maxZoomLevel: 3,
            }}>{children && (children)}</Gallery>)
        }
        else {
            return (<>{children && (children)}</>)
        }
    }

    type ImageWithProps = Props & ImageItem

    const Children = (props: ImageWithProps): React.JSX.Element => {
        const {image, title} = props

        return (
            <div className={`d-flex align-items-center overflow-hidden ${style.imageGridItem}`}>
                {popupCarousel ? (
                    <Item original={image} content={customContent(image, title)}>
                        {({ref, open}) => (
                            <img className={"w-100 h-100 object-fit-cover"} ref={ref} onClick={open} src={image} alt={""} />
                        )}
                    </Item>
                ) : (
                    <>
                        <div className={`d-flex w-100 h-100 align-items-center overflow-hidden`}>
                            <img className={"w-100 h-100 object-fit-cover"} src={image} alt={""} />
                        </div>
                        {title && (<div className={style.overlay}>{title}</div>)}
                    </>
                )}
            </div>
        )
    }

    return (
        <>
            <div className={`d-grid ${style.imageGrid}`}>
                <Container>
                    {images &&
                        images.map((item, index) => {
                            return <Children key={`image-grid-item-${index + 1}`} image={item.image} title={item.title} />
                        })
                    }
                </Container>
            </div>
        </>
    )
}

export default ImageGrid
