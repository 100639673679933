import {ImageItem} from "../view/component/image/ImageItem"

import convenients01 from "../assets/facilities/convenients/conv-canteen@3x.jpg"
import convenients02 from "../assets/facilities/convenients/conv-makeup@3x.jpg"
import convenients03 from "../assets/facilities/convenients/conv-meetingroom2@3x.jpg"
import convenients04 from "../assets/facilities/convenients/conv-parkinglot@3x.jpg"
import convenients05 from "../assets/facilities/convenients/conv-meetingroom@3x.png"

const data: Facility[] = [
    {
        id: 1,
        title: "stage A",
        slug: "stage-A",
    },
    {
        id: 2,
        title: "stage B-1",
        slug: "stage-B-1",
    },
    {
        id: 3,
        title: "stage B-2",
        slug: "stage-B-2",
    },
    {
        id: 4,
        title: "stage C",
        slug: "stage-C",
    },
    {
        id: 5,
        title: "stage D",
        slug: "stage-D",
    },
    {
        id: 6,
        title: "편의 & 부속 시설",
        slug: "Convenients",
        blocks: [
            {
                title: "",
                content: [
                    {
                        title: "식당",
                        image: convenients01,
                    }, {
                        title: "분장실",
                        image: convenients02,
                    }, {
                        title: "사무실",
                        image: convenients03,
                    }, {
                        title: "주차장",
                        image: convenients04,
                    }, {
                        title: "대회의실",
                        image: convenients05,
                    },
                ] as Array<ImageItem>
            },
        ],
    },
]

export interface Block {
    title: string
    content: ImageItem[] | string
}

export interface Facility {
    id: number
    title: string
    slug?: string
    blocks?: Block[]
}

const FacilityFetch = () => {

    const getFacilities = () => {
        return Promise.resolve(data)
    }

    const getFacility = (name: string) => {
        for (let fac of data) {
            if (fac.slug === name) {
                return Promise.resolve(fac)
            }
        }
        return Promise.reject("NotFound")
    }

    return {getFacilities, getFacility}
}

export default FacilityFetch
